import { Coupon } from '@wix/ambassador-ecommerce-coupons-v2-coupon/types';

import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getCouponSubtitle } from '@wix/loyalty-coupon-names';

interface Params {
  rewardName: string;
  coupon: Coupon;
}

export type ClaimedTitleBuildFn = ReturnType<typeof createClaimedCouponTitleBuilder>;

export const createClaimedCouponTitleBuilder =
  ({ translations }: ControllerFlowAPI, locale: string, currency: string) =>
  ({ rewardName, coupon }: Params) => {
    const { t } = translations;

    return `${rewardName} - ${getCouponSubtitle({
      coupon,
      referenceCoupon: true,
      locale,
      currency,
      t,
    })}`;
  };
